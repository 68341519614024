import tw, { styled } from "twin.macro"

export const Input = tw.input`
w-full px-2 py-1 mr-2 bg-gray-100 shadow-inner rounded-md border border-primary focus:shadow text-base focus:outline-none
`
export const Button = styled.button`
  ${tw`px-2 bg-secondary w-24 sm:w-32 text-gray-200 py-1 rounded shadow -ml-12 text-xs sm:text-base font-bold hover:bg-primary focus:outline-none hover:shadow-xl`}
  ${props =>
    props.disabled
      ? tw`bg-gray-700 hover:bg-gray-700 hover:shadow-none cursor-not-allowed`
      : null};
`

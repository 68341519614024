import React, { useRef, useState } from "react"
import tw from "twin.macro"
import ReCAPTCHA from "react-google-recaptcha"
import { Input, Button } from "./primitives/formItems"
import axios from "axios"

const FormContainer = tw.form`
  w-full
  `
const SignupBox = tw.div`
  flex items-center items-stretch w-full
  `
const SignupForm = ({ placeholder, buttonText }) => {
  const [email, setEmail] = useState("")
  const [executing, setExecuting] = useState(false)
  const recaptchaRef = useRef()

  const onSubmitWithReCAPTCHA = async e => {
    e.preventDefault()
    setExecuting(!executing)
    setEmail("")
    const token = await recaptchaRef.current.executeAsync()
    const payload = { email: email, humanKey: token }
    const response = await axios.post(
      "https://krhym8n8t9.execute-api.eu-west-1.amazonaws.com/prod/subscribe",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    console.log(response)
    recaptchaRef.current.reset()
  }

  return (
    <FormContainer onSubmit={onSubmitWithReCAPTCHA}>
      <SignupBox>
        <Input
          disabled={executing}
          type="text"
          name="email"
          placeholder={executing ? "Thank you. Now, please..." : placeholder}
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
        <Button type="submit" disabled={executing}>
          {executing ? "Check your email" : buttonText}
        </Button>
      </SignupBox>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LdR_boZAAAAAP_DonbTrpfg-B91HTVQ-Zb-lv81"
      />
    </FormContainer>
  )
}

export default SignupForm
